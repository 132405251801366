import { graphql } from "gatsby"
import React, { FC, useEffect, useState } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "./styles.module.scss"
import GetStarted from "src/components/Banners/GetStarted"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()
  const [content, setContent] = useState("")

  useEffect(() => {
    let unmounted = false

    async function getJobs() {
      const response = await fetch(
        "https://getflow.bamboohr.com/jobs/embed2.php?departmentId=0",
      )
      const content = await response.text()

      if (unmounted) return

      setContent(
        content.replace(/<a/gi, '<a target="_blank" rel="noopener noreferrer"'),
      )
    }

    getJobs()

    return () => {
      unmounted = true
    }
  }, [])

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={`content-wrapper ${styles.main}`}>
        <h1 className={styles.title}>{texts.header}</h1>
        <p className={styles.subtitle}>{texts.subheader}</p>
        <div className={styles.positions}>
          <div
            className={styles.bamboo}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        positions {
          name
          info
          link
        }
      }
    }
  }
`

export default Template
